<template>
  <v-container fluid class="pa-0" v-if="permisoVista('optimizacion','r')">
    <div v-if="loading">
      <div
          class="d-flex justify-center align-center flex-column"
          style="min-height: 80vh"
      >
        <v-progress-circular
            :size="90"
            :width="6"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <p class="text-center text-h4">Cargando</p>
      </div>
    </div>

    <div v-if="!loading">
      <div
          style="display: flex; flex-direction: column; justify-items: center; align-items: center; max-width: 100%;"
      >
        <div
            style="width: 100%; display: flex; flex-direction: column;"
        >
          <h3>
            Selecciona una máquina para visualizar la optimización de producción
          </h3>
          <v-autocomplete
              v-model="selectedMaquina"
              :items="filteredMaquinas"
              :item-text="getItemText"
              item-value="idMaquinaIns"
              :search-input.sync="search"
              placeholder="Buscar por nombre o ID de máquina"
              return-object
              class="autocomplete-input"
          >
            <template v-slot:selection="data">
              <v-chip
                  :input-value="data.selected"
                  color="primary"
                  text-color="white"
                  close
                  @click:close="data.parent.selectItem(null)"
                  @input="data.parent.selectItem(null)"
              >
                {{ data.item.nombre }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </div>

        <div
            v-if="areasProduccion.length === 0"
            style="width: 100%; display: flex; justify-items: center; margin-left: auto; margin-right: auto;"
        >
          <div
              class="text-center d-flex flex-column align-center justify-center"
              style="width: 100%;"
          >
            <div>
              <v-icon color="yellow darken-2" style="font-size: 6.25rem;">
                mdi-alert
              </v-icon>
            </div>
            <p class="text-center text-h5">
              No hay datos que mostrar, al parecer aún no se ha realizado ninguna
              simulación.
            </p>
          </div>
        </div>
        <div
            v-else
            class="d-flex overflow-x-auto overflow-y-auto"
            style="max-width: 100%;"
        >
          <div
              v-for="(area, idxArea) in areasProduccion"
              :key="`area-${idxArea}`"
              class="pa-3 mr-5"
              :style="area.activo?'background-color: rgba(255, 0, 0, 0.5); border-radius: 0.5rem;' :'background-color: rgba(207, 216, 220, 0.5); border-radius: 0.5rem;'"
          >
            <div class="mb-3 d-flex align-center justify-space-between">
              <div style="width: 24px"><!-- no eliminar --></div>
              <p class="mb-0 text-center text-h5 font-weight-bold">
                {{ area.nombre }}
              </p>
              <div v-if="area.lineas.length > 1 && permisoVista('optimizacion','u')">
                <v-tooltip
                    v-if="!area.modoEditar"
                    bottom
                    color="black"
                    class="white--text"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :disabled="area.disabled || !!area.savingOrden"
                        @click="setModoEditarArea(area)"
                    >mdi-pencil
                    </v-icon>
                  </template>
                  <span class="white--text">Editar</span>
                </v-tooltip>
                <template v-if="!!area.modoEditar">
                  <v-tooltip
                      v-if="permisoVista('optimizacion','u')"
                      bottom
                      color="black"
                      class="white--text"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!!area.savingOrden"
                          @click="saveNuevoOrdenArea(area)"
                      >mdi-content-save
                      </v-icon>
                    </template>
                    <span class="white--text">Guardar</span>
                  </v-tooltip>
                  <v-tooltip
                      bottom
                      color="black"
                      class="white--text"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!!area.savingOrden"
                          @click="cancelModoEditarArea(area)"
                      >mdi-cancel
                      </v-icon>
                    </template>
                    <span class="white--text">Cancelar</span>
                  </v-tooltip>
                </template>
              </div>
              <div v-else style="width: 24px"><!-- no eliminar --></div>
            </div>
            <div class="d-flex">
              <div
                  v-for="(linea, idxLinea) in area.lineas"
                  :key="`linea-${idxArea}-${idxLinea}`"
                  :class="`${linea.activo ? 'red lighten-2': linea.color} ${(idxLinea < area.lineas.length - 1) && 'mr-3'}`"
                  class="py-3 rounded-lg column-width text-center"
              >
                <div class="px-3">
                  <p
                      class="
                      mb-2
                      white--text
                      text-center text-h6
                      font-weight-medium
                    "
                      style="font-size: 1.125rem !important"
                  >
                    {{ linea.nombre}}
                  </p>
                  <div class="d-flex justify-space-between align-center pb-3" style="min-height: 2.25rem">
                    <div style="width: 24px"><!-- no eliminar --></div>
                    <v-tooltip
                        v-if="linea.maquinas != null && linea.maquinas.length > 0"
                        bottom
                        color="black"
                        class="white--text"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                        >mdi-robot-industrial-outline
                        </v-icon>
                      </template>
                      <span class="white--text">
                        Maquinas:
                        <ul>
                          <li v-for="(m, idxM) in linea.maquinas" :key="'m'+idxM">{{ m }}</li>
                        </ul>
                      </span>
                    </v-tooltip>
                    <div v-else style="width: 24px"><!-- no eliminar --></div>
                    <div v-if="linea.operaciones.length > 0 && permisoVista('optimizacion','u') && !area.modoEditar">
                      <v-tooltip
                          v-if="!linea.modoEditar"
                          bottom
                          color="black"
                          class="white--text"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="white"
                              @click="setModoEditar(linea,area)"
                          >mdi-sort
                          </v-icon>
                        </template>
                        <span class="white--text">Ordenar</span>
                      </v-tooltip>
                      <template v-if="!!linea.modoEditar">
                        <v-tooltip
                            v-if="permisoVista('optimizacion','u')"
                            bottom
                            color="black"
                            class="white--text"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="white"
                                :disabled="!!linea.savingOrden"
                                @click="saveNuevoOrden(linea,area)"
                            >mdi-content-save
                            </v-icon>
                          </template>
                          <span class="white--text">Guardar</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            color="black"
                            class="white--text"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="white"
                                :disabled="!!linea.savingOrden"
                                @click="cancelModoEditar(linea,area)"
                            >mdi-cancel
                            </v-icon>
                          </template>
                          <span class="white--text">Cancelar</span>
                        </v-tooltip>
                      </template>
                    </div>
                    <div v-else style="width: 24px"><!-- no eliminar --></div>
                  </div>
                  <v-col v-if="!!linea.savingOrden" cols="12" class="pa-0 pb-2">
                    <v-progress-linear
                        indeterminate
                        color="white"
                    ></v-progress-linear>
                  </v-col>
                </div>

                <div class="px-3 linea-container">
                  <draggable
                      :list="linea.operaciones"
                      :animation="200"
                      ghost-class="ghost-card"
                      class="draggable-area"
                      :group="!!area.modoEditar && `ordenes-${area.idArea}`"
                      :disabled="!linea.modoEditar || !!linea.savingOrden"
                      @change="onChangeDraggable($event,linea)"
                  >
                    <OrdenTrabajoCard
                        v-for="operacion in linea.operaciones"
                        :key="operacion.idOperacion"
                        :operacion="operacion"
                        :dependencias="dependencias"
                        :modoEdicion="(!linea.modoEditar || !!linea.savingOrden)"
                        class="mb-3"
                        :style="(!linea.modoEditar || !!linea.savingOrden) ? 'cursor: default' : 'cursor: move'"
                        @change="setDependencias"
                    />
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import OrdenTrabajoCard from "./OptimizacionProduccion/OrdenTrabajoCard.vue";

export default {
  components: {
    draggable,
    OrdenTrabajoCard,
  },
  data() {
    return {
      search: "",
      selectedMaquina: null,
      lastAdded: null,
      lastRemoved: null,
      areasProduccion: [],
      maquinas: [],
      loading: false,
      dependencias: []
    };
  },
  mounted() {
    this.initialize();
  },
  watch: {
    selectedMaquina() {
      this.getOptimizacionProduccion();
    }
  },
  computed: {
    filteredMaquinas() {
      if (this.maquinas?.length === 0) return [];

      if (!this.search) return this.maquinas;

      return this.maquinas.filter(maquina => {
        return (maquina.nombre && typeof maquina.nombre === "string" && this.search && typeof this.search === "string" && maquina.nombre.toLowerCase().includes(this.search.toLowerCase())) ||
            maquina.idMaquinaIns.toString() === this.search;
      });
    }
  },
  methods: {
    getItemText(item) {
      return item.nombre ? item.nombre.toLowerCase() : '';
    },
    async saveNuevoOrdenArea(area) {
      this.$set(area, 'savingOrden', true);

      const values = await Promise.all(area.lineas.map(linea => this.saveNuevoOrden(linea)));

      if (values.every(value => value)) {
        this.$set(area, 'modoEditar', false);
      }

      this.$set(area, 'savingOrden', false);
    },
    cancelModoEditarArea(area) {
      this.$set(area, 'modoEditar', false);
      area.lineas.forEach(linea => {
        this.cancelModoEditar(linea);
      });
    },
    setModoEditarArea(area) {
      area.lineas.forEach(linea => {
        this.setModoEditar(linea);
      });

      this.$set(area, 'modoEditar', true);
    },
    saveNuevoOrden(linea, area = null) {
      return new Promise((resolve) => {
        this.$set(linea, 'savingOrden', true);

        const operacionesOrdenadas = linea.operaciones.slice().reverse().map((operacion, index) => ({
          idOperacion: operacion.idOperacion,
          idConfiguracionArea: linea.idConfiguracionArea,
          prioridad: index,
          fijo:operacion.fijo
        }));

        axios
            .put('/OptimizacionProduccion/ModificarPrioridad', operacionesOrdenadas)
            .then(() => {
              this.$set(linea, 'savingOrden', false);
              this.$set(linea, 'modoEditar', false);
              this.$set(linea, 'viejoOrden', []);
              if (area) this.$set(area, 'disabled', false);

              resolve(true);
            })
            .catch(error => {
              this.$set(linea, 'savingOrden', false);
              console.log(error);

              resolve(false);
            });
      });
    },
    cancelModoEditar(linea, area = null) {
      this.$set(linea, 'modoEditar', false);
      this.$set(linea, 'operaciones', linea.viejoOrden.slice());
      this.$set(linea, 'viejoOrden', []);
      if (!area) return;

      if (!area.lineas.some(linea => linea.modoEditar)) {
        this.$set(area, 'disabled', false);
      }
    },
    setModoEditar(linea, area = null) {
      this.$set(linea, 'viejoOrden', linea.operaciones.slice());
      this.$set(linea, 'modoEditar', true);
      if (area) this.$set(area, 'disabled', true);
    },
    revertirUltimoMovimiento() {
      //Eliminamos del arreglo destino el ultimo elemento movido
      const indexLastAdded = this.lastAdded.linea.operaciones.findIndex(
          (operacion) => operacion.idOperacion === this.lastAdded.element.idOperacion
      );

      this.lastAdded.linea.operaciones.splice(indexLastAdded, 1);

      //Agregamos al arreglo origen el ultimo elemento movido
      this.lastRemoved.linea.operaciones.splice(this.lastRemoved.oldIndex, 0, {...this.lastRemoved.element});
    },
    onChangeDraggable(event, linea) {
      const eventKey = Object.keys(event)[0];
      if (eventKey === "added") {
        this.lastAdded = {
          element: event.added.element,
          linea
        }
      } else if (eventKey === "removed") {
        this.lastRemoved = {
          element: event.removed.element,
          oldIndex: event.removed.oldIndex,
          linea
        }
      }

      if (eventKey !== "removed") return;

      //Se valida si el movimiento es valido
      if (!this.lastAdded.element.idsLineas.includes(this.lastAdded.linea.idConfiguracionArea)) {
        this.revertirUltimoMovimiento();
        return;
      }
    },
    getMaquinas() {
      this.loading = true;
      axios
          .get("/MaquinaInstancias")
          .then((response) => {
            if (response.status === 200) {
              this.maquinas = response.data;
              this.getOptimizacionProduccion();
            } else {
              this.maquinas = [];
              this.loading = false;
            }
          })
          .catch(() => {
            this.maquinas = [];
            this.loading = false;
          });
    },
    getOptimizacionProduccion() {
      this.areasProduccion = [];
      this.loading = true;

      const urlTmp = this.selectedMaquina ? `/OptimizacionProduccion/?idMaquinaInst=${this.selectedMaquina.idMaquinaIns}` : "/OptimizacionProduccion";
      axios
          .get(urlTmp)
          .then((response) => {
            this.areasProduccion = response.data;
            this.loading = false;
          })
          .catch(() => {
            this.areasProduccion = [];
            this.loading = false;
          });
    },
    initialize() {
      this.colorIndex = 0;
      this.loading = false;
      this.maquinas = [];
      this.getMaquinas();
    },
    setDependencias(evt) {
      this.dependencias = evt;
      var item = evt;
      const isItemEmpty = item.length === 0;

      this.areasProduccion.forEach(area => {
        area.lineas.forEach(linea => {
          linea.operaciones.forEach(operacion => {
            if (isItemEmpty) {
              area.activo = false;
              linea.activo = false;
            } else if (item.includes(operacion.idOperacion)) {
              area.activo = true;
              linea.activo = true;
            } else if (operacion.idsDependientes.some(dep => item.includes(dep))) {
              area.activo = true;
              linea.activo = true;
            }
          });
        });
      });
    }
  },
};
</script>
<style scoped>
.linea-container {
  height: calc(100vh - 25rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.draggable-area {
  height: 100%;
}

.column-width {
  min-width: 15.625rem;
  width: 15.625rem;
}

.ghost-card {
  opacity: 0.5;
  background: rgb(247, 247, 247);
  border: 1px solid #546e7a;
}

.autocomplete-input {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
}
</style>
