<template>
	<div>
		<v-card
		:color="colorCarta"
		:class="{'card-selected': selected}"
		@dragstart="onDragStart"
		@dragend="onDragEnd"
	>
		<v-card-text>
			<v-container fluid>
				<v-row>
					<v-col :cols="computedCols" class="pa-0" >
						<v-checkbox
						class="mb-0 ml-8"
						v-model="operacion.fijo"
						:label="`Operación fija`"
						:disabled="modoEdicion"
						></v-checkbox>
						<p 
							class="mb-0 text-body-2 font-weight-medium"
							:class="`${getColorText} ${ selected && 'orange--text text--darken-4' }`"
						>
							{{
								`ODT: ${operacion.claveOT}`
							}}
						</p>
						<v-divider class="mx-0" :class="{ 'grey lighten-1': !esDependencia, 'white': esDependencia }"/>
						<p
							:class="'mb-0 text-body-2 '+ getColorText"
						>
							{{
								`[${operacion.articulo.codigo}] - ${operacion.articulo.nombre}`
							}}
						</p>
						<template v-if="operacion.subpaso.length > 0">
							<p
								v-for="(subpaso, idx) in operacion.subpaso"
								:key="idx"
								:class="'mb-0 text-body-2 '+ getColorText"
							>
								{{
									`• [${subpaso.codigo}] - ${subpaso.descripcion}`
								}}
							</p>
						</template>
						<v-divider class="mx-0" :class="{ 'grey lighten-1': !esDependencia, 'white': esDependencia }"/>
						<p
							:class="'mb-0 text-subtitle-2 font-weight-regular '+ getColorText"
						>
							Duracion: {{operacion.duracion}}
						</p>
						<v-btn x-small @click="editArea(operacion.areaCyM)" color="primary">Asignar operador</v-btn>
						<p v-if="operacion.operacionRealEnCurso" style="color:#32CD32 ">
							<v-icon style="color:#32CD32 " >mdi-adjust</v-icon>
							Operacion en curso
						</p>
						<p v-else > <v-icon>mdi-octagon</v-icon> Sin iniciar</p>
						<div>
							<ul>
								<li v-for="persona in operacion.operadoresAsignados" :key="persona.idPersona">
								  <span :style="persona.enCurso ?'color:#32CD32' : ''" >{{ persona.nombre }}</span>
								</li>
							  </ul>
						</div>
						<!-- <p
							:class="'mb-0 text-subtitle-2 font-weight-regular '+ getColorText"
						>
							Fin: {{operacion.fechaFin}}
						</p> -->
					</v-col>
					<v-col v-if="operacion.idsDependientes.length > 0" cols="2" class="align-self-center">
						<v-tooltip
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									@click="setDependencias"
									:color="getColor"
									:disabled="inhabilitar"

								>mdi-graph-outline</v-icon>
							</template>
							<span class="white--text">
								Mostrar {{ this.operacion.idsDependientes.length }} Operaciones Dependientes
							</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
	<area-control
      v-model="showArea"
      :area="area"
    ></area-control>
	</div>
</template>

<script>
import Area from '../../Control/Area.vue'
export default {
	components:{
		'area-control':Area,
	},
	props: {
		operacion: {
			type: Object,
			default: () => ({}),
		},
		dependencias: {
			type: Array,
			default: () => ([]),
		},
		modoEdicion:{
			type: Boolean,
			default: false
		}
	},
data() {
    return {
	  checkbox: false,
      seleccionado: false,
	  selected: false,
	  showArea: false,
	  area: {
      area:'',
      idArea:-1,
      operacionesCurso: 0,
    },
    };
  },
	computed:{
		computedCols(){
			return this.operacion.idsDependientes.length > 0 ? 10 : 12;
		},
		getColor(){
			return this.seleccionado ? 'red' : 'blue';
		},
		esDependencia(){
			return this.dependencias.includes(this.operacion.idOperacion);
		},
		getColorText(){
			return this.esDependencia ? 'white--text' : 'black--text';
		},
		colorCarta(){
			return this.esDependencia ? '#D50000' : 'white';
		},
		inhabilitar(){
			if(this.dependencias != null && this.dependencias.length > 0){
				return !this.seleccionado;
			}
			else return false;
		}
	},	
	methods:{
		onDragEnd(){
			setTimeout(() => {
				this.selected = false;
			}, 100);
		},
		onDragStart(){
			this.selected = true;
		},
		setDependencias(){
			if(!this.seleccionado) this.$emit('change',this.operacion.idsDependientes);
			else this.$emit('change',[]);
			this.seleccionado = !this.seleccionado;
		},
		editArea(area){
			this.area = area;
			this.showArea = true;
		}
	}
};
</script>

<style scoped>
.card-selected {
	/* border: 1px solid #FF6D00 !important; */
	box-shadow: 0 1px 10px 1px #FF6D00 !important;
}

.small-checkbox {
	font-size: 12px; /* Tamaño del texto */
	height: 24px; /* Altura del checkbox */
  }
  .small-checkbox .v-input__control {
	min-height: 24px; /* Ajustar el espacio interno */
  }
  .small-checkbox .v-label {
	font-size: 12px; /* Ajustar el tamaño del texto de la etiqueta */
  }
</style>